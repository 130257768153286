import * as React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Nav from '../components/nav'
import Sidebar from "../components/sidebar"
import Favicon from '../images/favicon.png'
import Apicontainer from "../components/apicontainer"
import Maincontainer from "../components/maincontainer"
import Heading from "../components/heading"
import Paragraph from "../components/paragraph"
import Badge from "../components/badge"
import Footer from "../components/footer"
import Code from "../components/code"
import CodeResp from "../components/coderesponse"
import Image from "../components/image"
import Alert from "../components/alert"
import Borderholder from "../components/borderholder"
import { useState } from "react"


const Page404 = ({}) =>{
  return (
    <>
          <Helmet>
        <title>404 Page </title>
        <meta name="description" content="404 Page - Monnify Developer Documentation " />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />        
        <meta name="theme-color" content="#03a9f4" />
        <link rel="shortcut icon" href={Favicon} type="image/x-icon" />
        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet"/>

      </Helmet>
      <Borderholder  type="api">

      <div className="col-span-12">
      <div class="grid place-items-center h-screen">
          <div class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
            <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                <div class="relative">
                    <div class="absolute">
                        <div class="">
                            <h1 class="my-2 text-gray-800 font-bold text-2xl">
                                Looks like you've found the
                                doorway to the great nothing
                            </h1>
                            <p class="my-2 text-gray-800">Sorry about that! Please visit our hompage to get where you need to go.</p>
                            <br />
                            <Link class="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center monnify-bg-primary text-white  focus:outline-none focus:ring-2 focus:ring-orange-700 mt-3 focus:ring-opacity-50" to="/">Take me there!</Link>
                        </div>
                    </div>
                    <div>
                    <Image src="404-2.png" classes="my-2" />
                    </div>
                </div>
            </div>
            <div>
            <Image src="monnify-dev-2.svg" classes="my-2" />
            </div>
        </div>   
        </div>   
     </div> 
       </Borderholder>
    </>
  )
}

export default Page404